$family-sans-serif: 'Open Sans', sans-serif;
$primary: #0171b9;

@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/grid/columns.sass';
@import '../node_modules/bulma/sass/elements/box.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/other.sass';
@import '../node_modules/bulma/sass/elements/image.sass';
@import '../node_modules/bulma/sass/elements/notification.sass';
@import '../node_modules/bulma/sass/elements/progress.sass';
@import '../node_modules/bulma/sass/elements/tag.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/helpers/flexbox.sass';
@import '../node_modules/bulma/sass/helpers/typography.sass';
@import '../node_modules/bulma/sass/helpers/visibility.sass';
@import '../node_modules/bulma/sass/helpers/spacing.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/components/media.sass';
@import '../node_modules/bulma/sass/components/modal.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
